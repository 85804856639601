import { navigate } from 'gatsby';
import { useEffect } from 'react';

/*
 * A little hacky solution for this issue: https://redmine.ack.ee/issues/94197.
 * I couldn't find the root cause. It seems that gatsby-plugin-react-intl correctly
 * redirects to the localized URL, but it doesn't trigger a re-render, so the page
 * remains blank (the non-localized routes are just empty pages with the redirect script).
 *
 * By calling the navigate method again (to the same redirected URL), it triggers
 * the re-render so no blank page is shown.
 */
const IntlRedirect = () => {
    useEffect(() => {
        const { pathname, search } = window.location;

        navigate(pathname + search, { replace: true });
    }, []);

    return null;
};

export default IntlRedirect;
